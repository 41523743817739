@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

/*
	Phantom by Pixelarity
	pixelarity.com @pixelarity
	License: pixelarity.com/license
*/

/* Form */

	form {
		.field {
			float: none !important;
			width: 100% !important;
			padding-left: 0 !important;
		}
	}

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		border: solid 2px _palette(fg) !important;

		&:hover {
			border-color: _palette(accent1) !important;
		}

		&.special {
			border: none !important;
		}
	}

/* Tiles */

	.tiles {
		article {
			> .image {
				&:before, &:after {
					display: none;
				}
			}
		}
	}

/* Header */

	#header {
		.logo {
			.symbol {
				display: none;
			}
		}

		nav {
			ul {
				li {
					a[href="#menu"] {
						text-indent: 0;
						width: auto;
						font-size: 0.8em;

						&:before, &:after {
							display: none;
						}
					}
				}
			}
		}
	}

/* Footer */

	#footer {
		> .inner {
			section {
				width: 50%;
				margin-left: 3em;

				&:first-child {
					margin-left: 0;
					width: 40%;
				}
			}
		}
	}